import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'image-2',
        category: 'Sveobuhvatna aplikacija koja olakšava studentskoj službi unos i praćenje podataka o studentima, profesorima, ispitima, ...',
        title: 'Fakultis (Informacioni sistem fakulteta)',
        link:'fakultis',
        id:'fakultis',
        column:'col-lg-4 col-md-6 col-sm-12'
    },
	{
        image: 'image-6',
        category: ' Aplikacija za elektronsku prijavu ispita, preko koje studenti mogu da brzo i lako vide podatke unete u informacioni sistem iz njihovog dosijea',
        title: 'e-Indeks (Studentski portal)',
        link:'eindeks',
        id: 'eindeks',
        column:'col-lg-4 col-md-6 col-sm-12'
    },
    {
        image: 'image-8',
        category: 'Aplikacija za pomoć nastavnicima i saradnicima za organizaciju nastave i ispita.',
        title: 'e-Nastavnik (Nastavnički portal)',
        link:'nasport',
        id: 'nasport',
        column:'col-lg-4 col-md-6 col-sm-12'
    },
    {
        image: 'image-5',
        category: 'Aplikacija koja služi za anketiranje, samoevaluaciju i za dobijanje raznih izveštaja o urađenim anketama.',
        title: 'e-Upitnik (Anketiranje i samoevaluacija)',
        link:'upitnik',
        id:'upitnik',
        column:'col-lg-4 col-md-6 col-sm-12'
    },
    {
        image: 'image-4 ',
        category: 'Aplikacija za online prijavu za polaganje prijemnog ispita.',
        title: 'e-Upis (Portal za upis)',
        link:'upis',
        id:'upis',
        column:'col-lg-4 col-md-6 col-sm-12'
    },
    {
        image: 'image-diploma',
        category: 'Оvom aplikacijom se proces pribavljanja i raspoređivanja podatka za diplomu automatizuje i lako se dobija diploma spremna za štampu.',
        title: 'Diploma',
        link:'diploma',
        id:'diploma',
        column:'col-lg-4 col-md-6 col-sm-12'
    },
  
  
]

class PortfolioListFakulteti extends Component{
    render(){
        const { stylevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${value.column}`} key={index}>
                        <a  href={`${value.link}`}>
                        <div className={`portfolio ${stylevariation}`}>
                        
                            <div className="thumbnail-inner" id={value.id}>
                            
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                                
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <h4><a href={`${value.link}`}>{value.title}</a></h4>
                                    <p>{value.category}</p>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`${value.link}`}>Detalji</a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                       </a>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioListFakulteti;