import React, { Component } from "react";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import Helmet2 from "../component/common/Helmet";


class About extends Component{
    render(){
        let title = 'O nama',
        description = 'Kompanija Stanković Soft je osnovana 2003. godine. Naš tim čine programeri, čije je radno iskustvo u izradi web orjentisanih informacionih sistema duže od 10 godina.';
        return(
            <React.Fragment>
              <Helmet2 pageTitle="" 
                pageDescription="Poslovne web aplikacije su naš osnovni proizvod. Jednostavne, lake za korišćenje, intuitivne, brze, sigurne i pouzdane. Stalno u kontaktu sa našim korisnicima, trudimo se da budemo od pomoći i čujemo svakog ko ima šta da nam kaže." 
                metaTitle=">Stanković Soft — Agencija za promet i izradu softvera i druge usluge"
                keywords="stankovicsoft,stankovic,poslovanja,razvoj web aplikacija,aplikacija,software,softver,izvršitelji,izvršitelj,teretane,program,upravljanje,evidencija,studenti,student,studenata,web,web aplikacije,studentski portal,nastavnicki portal, eindeks,mindeks,e-indeks,e indeks,e-upis,e upis,e-upitnik,e upitnik,e-nastavnik,e nastavnik,informacioni sistem,univerzitet,univerziteti,indeks,m-indeks,m indeks,upitnik,elektronska prijava ispita,upis,elektronski upis,falultis,fakulteti,fakultet,služba,sluzba,cronus,gym,gymgym,"
                />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Stanković Soft'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-10.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Fokusirani smo na najbitnije probleme</h3>
                                                    <p>Specijalizovani smo za izradu aplikacija koje Vam omogućavaju: kontrolu poslovnih procesa, bolju organizaciju rada, delegiranje zadataka zaposlenima, generisanju izveštaja o poslovanju itd, a sve u cilju povećanja efikasnost i produktivnosti klijenta.</p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="ostalo" style={{textAlign:'center', marginTop: 50}}>
                                        <div className="slide-btn"><a className="rn-button-style--2  btn-solid" href="portfolio" >Naše aplikacije</a></div>
                                    </div>
                                </div>
                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}


                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                       
                                        <div className="row mt--30">
                                            
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Poslujemo u skladu sa međunarodnim standardima</h3>
                                                    <p>U cilju nastavka obezbeđenja kvaliteta naših usluga, standardizacije poslovanja i zadovoljstva naših korisnika, agencija Stanković soft je implementirala standard ISO 9001 koji se odnosi na sistem menadžmenta kvalitetom. Implementacijom ovog standarda potvrđujemo kvalitet pruženih usluga sa namerom da se isti kontinuirano nastavi na najvišem nivou, uz poštovanje svih procedura poslovanja kao i do sada, a sve u cilju učvršćivanja poslovnih veza sa našim korisnicima, kako postojećim, tako i budućim.
                                                    Agencija Stankovićsoft je implementirala i standard ISO 27001 u cilju očuvanja sigurnosti sistema i očuvanja poverenja naših korisnika u ovom domenu, kao i u domenu adekvatnog upravljanja potencijalnim rizicima.
                                                    </p>
                                                </div>
                                                <br/><br/>
                                                <div className="about-us-list">
                                                    <h3 className="title">Šta je ISO 9001?</h3>
                                                    <p>ISO 9000 je međunarodni standard koji sadrži zahteve za sistem upravljanja kvalitetom u poslovnoj organizaciji koje organizacija mora ispuniti da bi uskladila svoje poslovanje sa međunarodno priznatim normama.</p>
                                                </div>
                                                <div className="about-us-list">
                                                    <h3 className="title">Šta je ISO 27001?</h3>
                                                    <p>Međunarodni standard ISO 27001 je standard koji podrazumeva uspostavljanje, primenu, održavanje i stalno poboljšavanje sistema menadžmenta bezbednošću informacija.</p>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/cert_9001.png" alt="About Images" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}


                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-11.jpg" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                       
                                        <div className="row mt--30">
                                            
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Posle mnogo godina postali smo bolji</h3>
                                                    <p>Prešli smo dug put razvoja i napredovanja i stvorili prijateljske odnose i dobru atmosferu. Profesionalni pristup, iskustvo u poslovanju i saradnja sa klijentima je ključ našeg dosadašnjeg uspeha.</p>
                                                </div>
                                                <br/><br/>
                                                <div className="about-us-list">
                                                    <h3 className="title">Ciljamo ka maksimalnom usavršavanju naših aplikacija </h3>
                                                    <p>Svaku našu aplikaciju nadograđujemo po potrebama klijenata kako sa tehničke strane, tako i sa brojem funkcionalnosti koje ona pruža. Nalaženjem novih načina da poboljšamo iskustvo korisnika i implementiranjem najboljih rešenja pružamo korisnicima sve kvalitetniji alat za rad.</p>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}



                 {/* Start CounterUp Area */}
                 <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Naša poslovanja:</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}
                {/*
                {/* Start Finding Us Area  *
                <div className="rn-finding-us-area rn-finding-us bg_color--1">
                    <div className="inner">
                        <div className="content-wrapper">
                            <div className="content">
                                <h4 className="theme-gradient">Find Your Work Now</h4>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that.</p>
                                <a className="rn-btn btn-white" href="/about">Get Started</a>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/finding-us-01.png" alt="Finding Images"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  *

                {/* Start Team Area  *
                <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h2 className="title">Skilled Team</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            
                            {/* Start Single Team  *
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-01.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Jone Due</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  *

                            {/* Start Single Team  *
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-02.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">BM. Alamin</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  *

                            {/* Start Single Team  *
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-03.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Jone Due</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  *

                        </div>
                    </div>
                </div>
                {/* End Team Area  *

                {/* Start Testimonial Area *
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About