import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

const divStyle = {
    WebkitTransition: 'all', // note the capital 'W' here
    msTransition: 'all' ,// 'ms' is the only lowercase vendor prefix
    backgroundColor:'#1c1d24'
  };
class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area " style={divStyle} >
                    <div className="footer-wrapper">
                        <div className="row align-items-start row--0 ">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Vreme je da poboljšamo jedni druge</span>
                                        <h2>Pokrenimo saradnju <br/> sada</h2>
                                        <a className="rn-button-style--2" href="/contact">
                                            <span>Kontakt</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" >
                                    <div className="row">
									
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Kontaktirajte nas</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:office@stankovicsoft.com">office@stankovicsoft.com</a></li>
                                                    <li><a href="mailto:stankovicsoft@gmail.com">stankovicsoft@gmail.com</a></li>
                                                    <li><a href="tel:+38118302735">018/302-735</a></li>
                                                    <li><a href="tel:+38118302794">018/302-794</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
										
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12" style={{marginTop:50}}>
                                            <div className="footer-link">
                                                <ul className="ft-link">
                                                    <li><a href="/terms" style={{weight:100, fontSize:13}}>Terms and Conditions</a></li>
                                                    <li><a href="/privacy" style={{weight:100, fontSize:13}}>Privacy Policy</a></li>
                                                    <li>
                                                        <a href="/standardi">
                                                            <img src="/assets/images/iso_9001_27001.png" id="cert9000img" style={{ width:"5em", height:'auto'}} alt="sertifikat" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © { new Date().getFullYear() } Stanković Soft</p>
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;